import "./tailwind.css";
import Alpine from "alpinejs";
import mask from "@alpinejs/mask";

Alpine.plugin(mask);

window.Alpine = Alpine;
queueMicrotask(() => {
  Alpine.start();
});
